export default [
  {
    question: 'What is Finger Print screening primarily used for?',
    correct: 2,
    answers: [
      'Detection of very recent use of drugs and alcohol',
      'Detection of historical drug use',
      'Detection of very recent drug use',
      'Detection of environmental exposure to drugs'
    ]
  },
  {
    question: 'What equipment is required to screen using the fingerprint method?',
    correct: 1,
    answers: [
      'Consent Form, Tests, Results Diagram, Confirmation kit',
      'Consent Form, Tests, Reader, Confirmation kit',
      'Consent Form, Tests, Buffer solution bottle, Confirmation kit',
      'Consent Form, Tests, A pad of ink, A Fingerprint Record Form'
    ]
  },
  {
    question: 'Where should we conduct screening?',
    correct: 1,
    answers: [
      'In a room with everyone else',
      'One to one in a private room',
      'In a room with other donors',
      'With a qualified nurse'
    ]
  },
  {
    question: 'Which 3 crucial bits of information are detailed on a consent form?',
    correct: 0,
    answers: [
      'Identification, medication and signature',
      'Identification, medical history and signature',
      'Place of birth, medical history and surname',
      'Surname, diet and location'
    ]
  },
  {
    question: 'Why should you ask the donor to select the test from the box?',
    correct: 3,
    answers: [
      'To remove accusations of result fixing',
      'To include them in the process',
      'So that the donor can see that the test is within its expiry date',
      'All of the above'
    ]
  },

  {
    question: 'Should the donor wash their hands before the screening?',
    correct: 0,
    answers: [
      'No',
      'Yes',
      'Only if the donor has sweaty hands',
      'Only if the donor has been wearing gloves'
    ]
  },
  {
    question: 'For how long should the donor push their finger on the collection pad when giving a sample?',
    correct: 3,
    answers: [
      '45 seconds',
      '30 seconds',
      '20 seconds',
      '10 seconds'
    ]
  },
  {
    question: 'Does the test device store the fingerprint of the donor?',
    correct: 1,
    answers: [
      'Yes',
      'No, it is not capable',
      'Yes, but the data is hidden',
      'Yes, the data is stored in the reader'
    ]
  },
  {
    question: 'When the sample has been taken, what must you do next?',
    correct: 2,
    answers: [
      'Place the cassette in the reader',
      'Wait for 10 minutes',
      'Close the sliding cover',
      'Complete the paperwork'
    ]
  },
  {
    question: 'When should you activate the buffer solution?',
    correct: 1,
    answers: [
      'Straight after the sample has been collected to save time',
      'When prompted by the reader',
      'Whilst the finger is on the collection pad',
      'The reader actives the buffer'
    ]
  },
  {
    question: 'How long does the incubation time take?',
    correct: 1,
    answers: [
      '15 minutes',
      '12 minutes',
      '10 minutes',
      '5 minutes'
    ]
  },
  {
    question: 'When do we send a sample for lab confirmation?',
    correct: 0,
    answers: [
      'When the result is a non-negative',
      'When we think it should be a non-negative',
      'All samples should be sent to the lab',
      'When the screening result is invalid'
    ]
  },
  {
    question: 'When do we collect the confirmation samples?',
    correct: 1,
    answers: [
      'At the next scheduled meeting with the donor',
      'As soon as possible after screening',
      'The same day',
      'After the donor has had a drink'
    ]
  },
  {
    question: 'Why is chain of custody important?',
    correct: 0,
    answers: [
      'Because you are ensuring the samples can\'t be switched or tampered with',
      'It is a legal requirement',
      'Because it prolongs the life of the sample',
      'It keeps the samples out of sunlight'
    ]
  },
  {
    question: 'Part of the collection for confirmation involves the donor washing their hands thoroughly. What should happen afterwards?',
    correct: 2,
    answers: [
      'Dry hands using the communal towel, and return to give the sample',
      'Dry hands using the communal towel, and do not touch anything for 15 minutes, then give the sample',
      'Dry hands on a fresh paper towel, and do not touch anything for 15 minutes, then give the sample',
      'Dry hands on a fresh paper towel, and return to give the sample'
    ]
  },
  {
    question: 'When should the sample be sent to the lab?',
    correct: 2,
    answers: [
      'Within a month',
      'To arrive 7 days after collection',
      'As soon as possible',
      'At the expiry date of the kit'
    ]
  },
  {
    question: 'What does the confirmation result show?',
    correct: 3,
    answers: [
      'Identifies that an analyte in a non-negative sample was an illicit substance',
      'Whether or not a non-negative with declared medication is a negative result',
      'That a declaration regarding what was found in the sample is true or false',
      'All of the above'
    ]
  },
  {
    question: 'What happens to the drug test after screening?',
    correct: 1,
    answers: [
      'Donor keeps the test',
      'The test is disposed of',
      'The test is kept as a record',
      'The test is sent to the lab'
    ]
  }
]
